import styled from 'styled-components';

import {
  getGridBase, getThemeColor, getThemePath, media,
} from '@utils/styled';

import Typography from '@common/components/Typography';
import { CMSBlockContainer } from '@common/components/CMSComponents/Container.styled';

export const Container = styled(CMSBlockContainer)``;

export const GridContainer = styled.div`
  ${getGridBase(12)}
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'equal', 5)};
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 3)};
`;

export const FactsGridItem = styled.div`
  display: flex;
  align-items: center;
  grid-column: span 12;
  border-radius: ${getThemePath(['borderRadiuses', 'normal.2'])}rem;
  ${({ theme }) => theme.getFluidSpacing('padding', 'scale', 3)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'scale', 20)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'scale', 20)};
  background-color: ${getThemeColor('hue.brightBlue')};
  ${({ theme }) => theme.getFluidFont('paragraph2')};
  font-weight: 500;
  word-break: break-word;
  hyphens: auto;

  ${media.from640up`
    grid-column: span 6;
  `}

  ${media.from1200up`
    grid-column: span 4;
  `}
`;
